.data-pair {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: stretch;

	.label {
		display: flex;
		font-size: 16px;
		font-weight: 500;
		line-height: 18px;
		letter-spacing: 0.1em;
		text-align: left;
		text-transform: uppercase;
		column-gap: 0.2rem;
	}

	.value {
		display: flex;
		font-size: 20px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: left;
		column-gap: 0.2rem;
	}
}
