@use "styles/sass/abstracts/variables" as var;

.button-component {
	padding: 0.813rem var.$spaces-sm;
	border-radius: 4px;
	font-size: var.$fonts-sm;
	line-height: 1rem;
	font-weight: var.$weight-semibold;
	color: var.$brand-primary;
	text-transform: uppercase;
	letter-spacing: 1px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	transition: transform 0.5s;

	img {
		margin-right: var.$spaces-sm;
	}

	&:disabled {
		cursor: not-allowed;
	}

	/* PRIMARY */
	&.button-primary {
		background: var.$brand-primary;
		color: var.$grayscale-white;
		border: 1px solid var.$brand-primary;

		&:active {
			background: var.$brand-primary-dark;
		}

		.disabled {
			background: var.$grayscale-steel-gray;
			border-color: var.$grayscale-steel-gray;

			&:active {
				background: var.$grayscale-steel-gray;
			}
		}
	}

	&.button-outline {
		border: 1px solid var.$grayscale-light-gray;
		background: var.$grayscale-white;

		&:active {
			background: var.$grayscale-light;
			border-color: var.$grayscale-light;
		}

		&:disabled {
			color: var.$grayscale-steel-gray;

			&:active {
				background: var.$grayscale-white;
			}
		}
	}

	&.button-text {
		border-color: transparent;
		background-color: transparent;

		&:active {
			background-color: var.$grayscale-light;
		}

		&:disabled {
			color: var.$grayscale-steel-gray;

			&:active {
				background-color: var.$grayscale-white;
			}
		}
	}
}
