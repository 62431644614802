@use '../abstracts/variables' as var;

.full-screen {
    height: 100vh;
    width: 100vw;
}

.height--full {
    height: 100%;
}

.width--full {
    width: 100%;
}

.width--fit-content {
    width: fit-content;
}

.container--full {
    height: 100%;
    width: 100%;
}

%stack-shared {
    display: flex;
    flex-direction: column;
}

.stack {
    @each $suffix, $space in var.$spaces {
        &--#{$suffix} {
            @extend %stack-shared;

            > *:not(:last-child) {
                margin-bottom: $space;
            }
        }
    }
}

%inline-shared {
    display: flex;
    flex-direction: row;
}

.inline {
    @each $suffix, $space in var.$spaces {
        &--#{$suffix} {
            @extend %inline-shared;

            > *:not(:last-child) {
                margin-right: $space;
            }
        }
    }
}

.row > div {
    margin-bottom: var.$spaces-md;

    @media only screen and (max-width: 576px) {
        margin-bottom: var.$spaces-sm;
    }
}
