@use "styles/sass/abstracts/variables" as var;

.header {
	font-weight: bold;
	background-color: var.$brand-primary;
	height: 4.5rem;
	width: 100%;
	display: flex;
	align-items: center;
	color: #FFF;
	text-align: center;

	* {
		font-family: Lato;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: 2.52px;
	}
}

.title {
	display: inline-flex;
	align-items: center;
}
