@import "styles/sass/abstracts/__abstracts";

.backdrop {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	&.dark-blue {
		background-color: $brand-primary;
	}
}

@media screen and (max-width: 700px) {
	.backdrop {
		img {
			width: 80%;
		}
	}
}
