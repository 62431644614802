@use 'styles/sass/abstracts/variables' as var;

.footer {
	color: var.$grayscale-black;
	display: grid;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	gap: 4px;
	padding: 16px 24px;
	line-height: normal;
	height: fit-content;
	max-height: var.$max-footer-height;
}

@media only screen and (max-width: 600px) {
	.footer {
		font-size: 14px;
		gap: 16px;
		padding: 20px;
	}
}
