/*** SPACING ***/
$spaces-xs: 0.25rem; // 4px
$spaces-sm: 0.5rem; // 8px
$spaces-md: 1rem; // 16px
$spaces-lg: 1.5rem; // 24px
$spaces-xl: 2rem; // 32px
$spaces-xxl: 2.5rem; // 40px

$spaces: (
	xs: $spaces-xs,
	sm: $spaces-sm,
	md: $spaces-md,
	lg: $spaces-lg,
	xl: $spaces-xl,
	xxl: $spaces-xxl,
);

/*** FONTS ***/
$fonts-xs: 0.75rem; // 12px
$fonts-sm: 0.875rem; // 14px
$fonts-md: 1rem; // 16px
$fonts-lg: 1.125rem; // 18px
$fonts-xl: 1.25rem; // 20px
$fonts-xxl: 1.5rem; // 24px

$fonts: (
	xs: $fonts-xs,
	sm: $fonts-sm,
	md: $fonts-md,
	lg: $fonts-lg,
	xl: $fonts-xl,
	xxl: $fonts-xxl,
);

$weight-regular: 400;
$weight-medium: 500;
$weight-semibold: 600;

$font_weights: (
	regular: $weight-regular,
	medium: $weight-medium,
	semibold: $weight-semibold,
);

$letter-spacing-lg: 0.1rem;

/*** COLORS ***/
$brand-primary: #14307f;
$brand-primary-light: #6495f7;
$brand-primary-dark: #0e3581;
$brand-secondary: #cddaf3;
$brand-secondary-light: #ffc533;
$brand-secondary-dark: #e5a500;
$grayscale-white: #ffffff;
$grayscale-light: #f2f4f8;
$grayscale-lighter-gray: #f0f0f0;
$grayscale-light-gray: #e4e9f1;
$grayscale-steel-gray: #6a7ca0;
$grayscale-blue-gray: #546383;
$grayscale-carbono-gray: #525c6f;
$grayscale-dark: #303541;
$grayscale-black: #3d3d3d;
$support-success: #55df00;
$support-error: #f43d00;
$support-timeline-success: linear-gradient(90deg, #42ae00 35.94%, #55df00 35.95%);
$support-timeline-alert: linear-gradient(90deg, #c7a000 57.81%, #f8c800 57.82%);
$support-timeline-urgent: linear-gradient(90deg, #c23000 83.33%, #f43d00 83.34%);

$colors: (
	brand: (
		primary: $brand-primary,
		primary-light: $brand-primary-light,
		primary-dark: $brand-primary-dark,
		secondary: $brand-secondary,
		secondary-light: $brand-secondary-light,
		secondary-dark: $brand-secondary-dark,
	),
	grayscale: (
		'white': $grayscale-white,
		light: $grayscale-light,
		lighter-gray: $grayscale-lighter-gray,
		light-gray: $grayscale-light-gray,
		steel-gray: $grayscale-steel-gray,
		blue-gray: $grayscale-blue-gray,
		carbono-gray: $grayscale-carbono-gray,
		dark: $grayscale-dark,
		black: $grayscale-black,
	),
	support: (
		success: $support-success,
		error: $support-error,
		timeline-success: $support-timeline-success,
		timeline-alert: $support-timeline-alert,
		timeline-urgent: $support-timeline-urgent,
	),
);

/*** DIRECTIONS ***/
$directions: (
	l: 'left',
	r: 'right',
	t: 'top',
	b: 'bottom',
);

/*** LAYOUTS ***/
$header-height: 4.5rem;
$footer-height: 4.5rem;
$max-footer-height: 5.5rem;
