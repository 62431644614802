@use '../abstracts/variables' as var;

.font-size {
    @each $suffix, $size in var.$fonts {
        &--#{$suffix} {
            font-size: $size;
        }
    }
}

.letter-spacing--lg {
    letter-spacing: var.$letter-spacing-lg;
}

%typography-color {
    color: var.$grayscale-dark;
}

%headline {
    @extend %typography-color;
    font-weight: var.$weight-semibold;
}

%body {
    @extend %typography-color;
    font-weight: var.$weight-regular;
}

%button {
    @extend %typography-color;
    font-weight: var.$weight-semibold;
}

.headline-1 {
    @extend %headline;
    font-size: var.$fonts-xxl;
    line-height: var.$fonts-xxl;
}

.headline-2 {
    @extend %headline;
    font-size: var.$fonts-xl;
    line-height: var.$fonts-xl;
}

.headline-3 {
    @extend %headline;
    font-size: var.$fonts-lg;
    line-height: var.$fonts-lg;
}

.headline-4 {
    @extend %headline;
    font-size: var.$fonts-md;
    line-height: var.$fonts-md;
}

.headline-5 {
    @extend %headline;
    font-size: var.$fonts-sm;
    line-height: var.$fonts-sm;
}

.body-1 {
    @extend %body;
    font-size: var.$fonts-lg;
    line-height: var.$fonts-lg;
}

.body-2 {
    @extend %body;
    font-size: var.$fonts-md;
    line-height: var.$fonts-md;
}

.subtitle-1 {
    @extend %body;
    font-size: var.$fonts-sm;
    line-height: var.$fonts-sm;
}

.button-1 {
    @extend %button;
    font-size: var.$fonts-md;
    line-height: var.$fonts-md;
}

.button-2 {
    @extend %button;
    font-size: var.$fonts-sm;
    line-height: var.$fonts-sm;
}

.caption {
    @extend %typography-color;
    font-weight: var.$weight-medium;
    font-size: var.$fonts-md;
    line-height: var.$fonts-md;
}

.overline-1 {
    @extend %typography-color;
    font-weight: var.$weight-medium;
    font-size: var.$fonts-xs;
    line-height: var.$fonts-xs;
    letter-spacing: 0.1rem;
}

.overline-2 {
    @extend %typography-color;
    font-weight: var.$weight-regular;
    font-size: var.$fonts-xs;
    line-height: var.$fonts-xs;
}
