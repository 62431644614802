@import 'styles/sass/abstracts/__abstracts';

.title {
	font-weight: bold;
	padding: 1rem;
}

.text {
	padding-left: 1rem;
}

main {
	height: 100%;
	width: 100%;

	header {
		background-color: $brand-primary;
		color: $grayscale-white;
		position: fixed;
		top: 0;
		width: 100%;
		height: $header-height;
	}

	section {
		background-color: $grayscale-lighter-gray;
		align-items: center;
		width: 100%;
		max-width: 100%;
		height: calc(100% - 4.5rem);
		padding: 0.5rem 0.5rem calc($max-footer-height + 0.5rem) 0.5rem;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		row-gap: 0.5rem;
	}

	footer {
		background-color: white;
		bottom: 0;
		position: fixed;
		width: 100%;
		height: $footer-height;
		padding: 16px 24px;
	}
}
