* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', 'Poppins', sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:-webkit-any-link {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body.modal-open {
    width: 100%;
    overflow: hidden;
    position: fixed;
}
