@use "styles/sass/abstracts/variables" as var;

.ellipsis-pagination {
	width: 40px;
	display: flex;
	justify-content: center;
	padding-top: var.$spaces-sm;
}

.pagination-component {
	padding-bottom: var.$spaces-lg;
	display: flex;
	justify-content: center;
}
