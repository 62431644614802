.language-switcher {
	padding: 0;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	min-width: fit-content;

	span {
		font-size: small;
	}

	.btn-language {
		padding: 0;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;

		.flag {
			height: 1rem;
			margin: 0;
		}
	}
}
