@use "styles/sass/abstracts/variables" as var;


.date-separator {
	align-items: center;
	background-color: var.$brand-primary;
	color: white;
	display: flex;

	flex-direction: row;
	font-family: 'Lato';
	font-weight: 700;

	height: 35px;
	justify-content: center;

	line-height: 80%;
	margin: 0;
	max-width: 1064px;
	padding: 5px;
	width: 100%;

	svg {
		height: 4vh;
		margin: 0 1vh;
	}
}
