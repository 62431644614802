.timeZone {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: 10px;
  svg {
    margin-right: 0;
  }
}

.timeZoneText {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 8px;
  margin-right: 10px;
}
