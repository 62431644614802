@import "styles/sass/abstracts/__abstracts";

.italicized {
	font-style: italic;
}

.dark-blue {
	color: $brand-primary-dark;
}

.fids-card-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 1064px;
	padding: 1rem;
	column-gap: 1rem;
	row-gap: 0.5rem;
	border-radius: 4px;
	background-color: white;
	box-shadow: 0px 8px 24px 0px #1D1D1D29;
	color: $grayscale-dark;

	.top-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 2.5rem;


		font-size: 32px;
		font-weight: 700;
		line-height: 38px;
		letter-spacing: 0em;
		text-align: left;

		.airline-logo-slot {
			max-height: 2.5rem;
			max-width: 25%;
			border-radius: 4px;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;

			img {
				max-width: 110%;
				height: 100%;
				width: unset;

				&.small-airline-logo {
					display: none;
				}
			}
		}
	}

	.divider {
		height: 2px;
		width: 100%;
		background-color: $grayscale-lighter-gray;

	}

	.bottom-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 3rem;
		row-gap: 2rem;

		.airport-slot, .status-slot {
			width: calc(28% - 4rem/3);
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			text-wrap: nowrap;
		}

		.time-slot {
			width: calc(44% - 4rem/3);
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			column-gap: 10%;
		}
	}
}

@media screen and (max-width: 700px) {
	.fids-card-container{
		.top-row {
			.airline-logo-slot {
				max-height: 2.5rem;
				max-width: 5rem;

				img {
					height: unset;
					width: 100%;

					&.small-airline-logo {
						display: initial;
					}

					&.large-airline-logo {
						display: none;
					}
				}
			}
		}

		.bottom-row {
			flex-direction: column;
			justify-content: flex-start;
			align-items: stretch;
			width: 100%;
			height: max-content;
			row-gap: 1rem;

			.airport-slot {
				width: 100%;

			}

			.time-slot {
				width: 100%;
				justify-content: space-between;
			}

			.status-slot {
				width: 100%;

			}
		}
	}
}
